import { PropTypes } from "prop-types";
import useNewsletter from "@/hooks/useNewsletter";
import { cn } from "@/utils/cn";
import { useTranslation } from "react-i18next";
import HTMLParser from "../HTMLParser";

const FooterNewsletter = ({ listId, title, text }) => {
  const { message, onSubmit, email, setEmail, sentRequest } =
    useNewsletter(listId);

  const { t } = useTranslation();

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <div className="flex items-center justify-center lg:mb-7 lg:justify-start">
      <div className="w-full">
        {title && (
          <div className="mb-5 text-[32px] leading-[1.1] lg:mb-3 lg:max-w-[300px] [&_em]:font-serif [&_em]:not-italic">
            <HTMLParser html={title} />
          </div>
        )}
        {text && <p className="text-base leading-normal lg:text-base">{text}</p>}
        <form
          className="mb-9 mt-5 border-b border-dark-green"
          onSubmit={handleSubmit}
        >
          <div className="relative flex items-center">
            <label className="block w-full">
              <span className="sr-only">{t("form.email")}</span>
              <input
                className={cn(
                  "!autofill:bg-transparent h-14 w-full appearance-none bg-transparent px-4 font-serif text-2xl placeholder:text-stormcloud",
                  {
                    "text-stormcloud": sentRequest,
                  },
                )}
                type="email"
                id="footer-email"
                name="email"
                value={!sentRequest ? email : message.content}
                onChange={e => setEmail(e.target.value)}
                autoComplete="email"
                placeholder={t("form.email")}
                required
                disabled={sentRequest}
              />
            </label>
            <button className="absolute -top-1/2 right-0 inline-flex h-14 w-11 translate-y-1/2 transform items-center justify-center  text-dark-green">
              <span className="sr-only">{t("action.newsletter_submit")}</span>
              <svg
                width="21"
                height="25"
                viewBox="0 0 21 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.041748 12.9039H18.3622L8.02567 23.2405L9.00006 24.2148L21.0001 12.2148L9.00006 0.214844L8.02567 1.18923L18.3622 11.5257H0.041748V12.9039Z"
                  fill="#104234"
                />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

FooterNewsletter.propTypes = {
  listId: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default FooterNewsletter;
